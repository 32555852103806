import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { InView } from 'react-intersection-observer';

import AIMS_FLAVORS from 'lib/aimsFlavors';
import Breakpoints from 'lib/Breakpoints';
import getNavbarHeight from 'lib/getNavbarHeight';

import { Placeholder } from 'components/Picture/Placeholder';
import { VideoPlayerContext } from 'lib/ContextTypes/videoPlayer';

import './styles.themed.scss';

const block = 'sticky-pip';

function StickyPIP(
  props,
  context,
) {
  const {
    children,
    className,
    getShouldStickOnViewportExit,
    placeholderClassName,
    enabled,
    onInViewChange,
    onClose,
    onStuckChange,
    shouldStickOnlyOnFirstView,
    pipAlignDesktop,
    pipAlignMobile,
    noStuckChangeInFullscreen,
  } = props;

  const { t } = useTranslation();

  const [isClosed, setIsClosed] = React.useState(false);
  const [isInView, setIsInView] = React.useState(true);
  const [shouldStickOnViewportExit, setShouldStickOnViewportExit] = React.useState(true);
  const [hasBeenInView, setHasBeenInView] = React.useState(false);
  const videoPlayerContext = React.useContext(VideoPlayerContext);
  const fullscreenActive = !!document.fullscreenElement;

  const handleObserverCallback = React.useCallback((inView) => {
    if (noStuckChangeInFullscreen && fullscreenActive) {
      return;
    }
    setIsInView(inView);
    if (inView) {
      // setting the same value a 2nd time doesn't trigger a rerender
      setHasBeenInView(true);
      setIsClosed(false);
    }
    if (!inView && typeof getShouldStickOnViewportExit === 'function') {
      setShouldStickOnViewportExit(getShouldStickOnViewportExit());
    }
    if (typeof onInViewChange === 'function') {
      onInViewChange(inView);
    }
  }, [onInViewChange, getShouldStickOnViewportExit, noStuckChangeInFullscreen, fullscreenActive]);

  // eslint-disable-next-line react/destructuring-assignment
  const setStickyVideoFlag = context?.setStickyVideoFlag
  || videoPlayerContext?.setStickyVideoFlag;

  const shouldStickBasedOnHasBeenInView = shouldStickOnlyOnFirstView
    ? hasBeenInView
    : true; // stick regardless if `shouldStickOnlyOnFirstView` is false

  const isStuck = (
    enabled
    && shouldStickBasedOnHasBeenInView
    && !isInView
    && !isClosed
    && shouldStickOnViewportExit
    && !(noStuckChangeInFullscreen && fullscreenActive)
  );

  React.useEffect(() => {
    if (noStuckChangeInFullscreen && fullscreenActive) {
      return;
    }
    if (typeof setStickyVideoFlag === 'function') {
      setStickyVideoFlag(isStuck);
    }
  }, [setStickyVideoFlag, isStuck, noStuckChangeInFullscreen, fullscreenActive]);

  React.useEffect(() => {
    if (noStuckChangeInFullscreen && fullscreenActive) {
      return;
    }
    if (typeof onClose === 'function') {
      onClose(isClosed);
    }
  }, [onClose, isClosed, noStuckChangeInFullscreen, fullscreenActive]);

  React.useEffect(() => {
    if (noStuckChangeInFullscreen && fullscreenActive) {
      return;
    }
    if (typeof onStuckChange === 'function') {
      onStuckChange(isStuck);
    }
  }, [onStuckChange, isStuck, noStuckChangeInFullscreen, fullscreenActive]);

  const navbarHeight = getNavbarHeight(Breakpoints);

  return (
    <InView
      className={className}
      onChange={handleObserverCallback}
      rootMargin={`${-navbarHeight}px 0px 0px 0px`}
      threshold={0.3}
    >
      <Placeholder
        responsiveFlavors={{ s: AIMS_FLAVORS.FOCAL_860x484 }}
        className={placeholderClassName}
      />
      <div
        className={classNames(block, {
          [`${block}--stuck`]: isStuck,
          [`${block}--desktopTop`]: pipAlignDesktop === 'top',
          [`${block}--desktopBottom`]: pipAlignDesktop === 'bottom',
          [`${block}--mobileTop`]: pipAlignMobile === 'top',
          [`${block}--mobileBottom`]: pipAlignMobile === 'bottom',
        })}
        data-testid="sticky-pip__sticky-container"
      >
        {children}
        <div className={`${block}__close`}>
          <button
            type="button"
            className={`${block}__close-button icon icon-close`}
            onClick={() => setIsClosed(true)}
          >
            <span className={`${block}__close-label`}>
              {t('Close')}
            </span>
          </button>
        </div>
      </div>
    </InView>
  );
}

StickyPIP.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  getShouldStickOnViewportExit: PropTypes.func,
  placeholderClassName: PropTypes.string,
  onClose: PropTypes.func,
  onInViewChange: PropTypes.func,
  onStuckChange: PropTypes.func,
  shouldStickOnlyOnFirstView: PropTypes.bool,
  enabled: PropTypes.bool,
  pipAlignDesktop: PropTypes.oneOf(['top', 'bottom']),
  pipAlignMobile: PropTypes.oneOf(['top', 'bottom']),
  noStuckChangeInFullscreen: PropTypes.bool,
};

StickyPIP.contextTypes = {
  setStickyVideoFlag: PropTypes.func,
};

StickyPIP.defaultProps = {
  children: null,
  className: undefined,
  getShouldStickOnViewportExit: null,
  placeholderClassName: undefined,
  onClose: null,
  onInViewChange: null,
  onStuckChange: null,
  shouldStickOnlyOnFirstView: true,
  enabled: true,
  pipAlignDesktop: 'bottom',
  pipAlignMobile: 'bottom',
  noStuckChangeInFullscreen: false,
};

export { StickyPIP };
